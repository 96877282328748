* {
  padding: 0;
  margin: 0;
  color: #fff;
}

body {
  scroll-behavior: smooth;
  background-image: url('../assets/images/background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  /* Ảnh nền cố định */
  overflow-x: hidden;
  min-height: 100vh;
}

html {
  box-sizing: border-box;
  font-size: 20px;
  font-weight: 500;
  font-family: "Merienda", cursive;
  font-optical-sizing: auto;
}


/* Scroll */

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* // swiper */

.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  background-color: var(--body_color) !important;
}

.component_background {
  background-color: rgba(0, 47, 86, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 0.5rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}


.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  background-color: #fff !important;
}