.pc-header_container {
  padding: 1rem;
  z-index: 1;
  position: relative;
  top: 0;
  transition: all 0.5s ease;
  &.fixed-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: all 0.5s ease;
    background-color: rgba(0, 47, 86, 0.7);
    backdrop-filter: blur(10px);
  }
  .row {
    .header_item {
      text-align: center;
      font-size: 1.2rem;
      font-weight: 600;
      transition: all 0.3s linear;
      &:hover {
        cursor: pointer;
        color: #00ffff;
        text-shadow: 3px 3px 10px rgba(0, 255, 255, 0.81);
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .pc-header_container {
    .row {
      .header_item {
        text-align: center;
        font-size: 1rem;
        font-weight: 600;
        transition: all 0.3s linear;
        &:hover {
          cursor: pointer;
          color: #00ffff;
          text-shadow: 3px 3px 10px rgba(0, 255, 255, 0.81);
        }
      }
    }
  }
}
