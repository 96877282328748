.intro-component_container {
  margin-top: 1rem;
  .container {
    .row {
      background-color: rgba(0, 47, 86, 0.5);
      backdrop-filter: blur(10px);
      padding: 1rem;
      border-radius: 0.5rem;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
      .content-left {
        display: flex;
        align-items: center;
        justify-content: center;
        .content-left_image {
          width: 300px;
          height: 300px;
          background-image: url("../../assets/images/avatar.jpg");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 50%;
        }
      }
      .content-right {
        display: flex;
        justify-content: center;
        flex-direction: column;
        .content-right_title {
          font-size: 1.3rem;
          font-weight: 700;
        }
        .content-right_text {
          line-height: 1.5;
        }
        .content-right_social {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 1rem;
          gap: 1rem;
          i {
            font-size: 3rem;
            transition: all 0.3s ease;
            &:hover {
              cursor: pointer;
              color: #00ffff;
              text-shadow: 3px 3px 3px rgba(0, 255, 255, 0.81);
            }
          }
        }
        .button_wrapper {
          text-align: center;
          margin-top: 1rem;
          .btn_download {
            color: #fff;
            border: 0.5px solid #ccc;
            position: relative;
            z-index: 1;
          }
          .btn_download::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 0;
            z-index: -1;
            background-color: rgba(255, 255, 255, 0.4);
            transition: all 0.3s linear;
          }
          .btn_download:hover::before {
            width: 100%;
            font-weight: 700;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .intro-component_container {
    margin-top: 1rem;
    .container {
      .row {
        .content-left {
          display: flex;
          align-items: center;
          justify-content: center;
          .content-left_image {
            width: 250px;
            height: 250px;
            background-image: url("../../assets/images/avatar.jpg");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 50%;
          }
        }
        .content-right {
          display: flex;
          justify-content: center;
          flex-direction: column;
          .content-right_title {
            font-size: 1rem;
            font-weight: 700;
          }
          .content-right_text {
            line-height: 1.5;
            font-size: 0.9rem;
          }
          .content-right_social {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 1rem;
            gap: 1rem;
            i {
              font-size: 2rem;
              transition: all 0.3s ease;
              &:hover {
                cursor: pointer;
                color: #00ffff;
                text-shadow: 3px 3px 3px rgba(0, 255, 255, 0.81);
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .intro-component_container {
    .container {
      .row {
        flex-direction: column;
        .content-right {
          .content-right_title {
            text-align: center;
          }
          .content-right_text {
            text-align: center;
          }
          .content-right_social {
            i {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
}
