.footer_container {
  padding: 2rem;
  margin-top: 2rem;
  text-align: center;
  background-color: rgba(0, 47, 86, 0.7);
  backdrop-filter: blur(10px);
  .copy-right {
    font-size: 1.5rem;
    font-weight: 600;
  }
}

@media (max-width: 767px) {
  .footer_container {
    .copy-right {
      font-size: 1rem;
    }
  }
}
