.project-item_container {
  position: relative;
  margin-bottom: 2rem;
  height: 250px;
  .image_item {
    border-radius: 0.5rem;
    position: absolute;
    background-image: url("../../../assets/images/logo.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .desc_item {
    z-index: 1;
    position: absolute;
    border-radius: 0.5rem;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .desc_name {
      font-size: 1.5rem;
      font-weight: 600;
      width: 500px;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      text-wrap: nowrap;
    }
    .desc_link {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      i {
        font-size: 1.7rem;
        transition: all 0.3s linear;
        &:hover {
          color: #00ffff;
          text-shadow: 3px 3px 10px rgba(0, 255, 255, 0.81);
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .project-item_container {
    height: 150px;

    .desc_item {
      z-index: 1;
      position: absolute;
      border-radius: 0.5rem;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0.5rem;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .desc_name {
        font-size: 1rem;
        font-weight: 600;
        width: 250px;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        text-wrap: nowrap;
      }
      .desc_link {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        i {
          font-size: 1.5rem;
          transition: all 0.3s linear;
          &:hover {
            color: #00ffff;
            text-shadow: 3px 3px 10px rgba(0, 255, 255, 0.81);
            cursor: pointer;
          }
        }
      }
    }
  }
}
